import axios from "axios";

// URLs de los servidores
const primaryApi = "https://technical.ddns.net:3001";
const secondaryApi = "https://backend-1-v7yh.onrender.com";

// Configuración inicial con la URL principal
let currentAPIURL = primaryApi;

const config = {
  get API_URL() {
    return currentAPIURL; // Devuelve siempre la URL activa
  },
};

// Crear una instancia de Axios configurada con la URL activa
const api = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Función para cambiar dinámicamente la URL activa
function setAPIUrl(url) {
  if (url === primaryApi || url === secondaryApi) {
    currentAPIURL = url;
    api.defaults.baseURL = currentAPIURL; // Actualizar la instancia de Axios
    console.log(`API_URL cambiado dinámicamente a: ${currentAPIURL}`);
  } else {
    console.error("URL no válida. No se realizó el cambio.");
  }
}

// Interceptor para agregar el token de autenticación a cada solicitud
api.interceptors.request.use(
  (config) => {
    // Obtener el token del almacenamiento local
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // Agregar el token al encabezado Authorization
    }
    return config;
  },
  (error) => {
    return Promise.reject(error); // Manejar errores de configuración
  }
);

// Interceptor para manejar errores y failover automático
api.interceptors.response.use(
  (response) => response, // Respuesta exitosa
  async (error) => {
    if (error.code === "ERR_NETWORK" || error.message.includes("Network Error")) {
      console.error(`Error de red detectado en ${currentAPIURL}: ${error.message}`);

      // Cambiar al servidor alternativo si el servidor actual falla
      const newURL = currentAPIURL === primaryApi ? secondaryApi : primaryApi;

      setAPIUrl(newURL); // Cambiar la URL activa

      // Reintentar la solicitud con el nuevo servidor
      if (!error.config.__retry) {
        error.config.__retry = true; // Marcar la solicitud como reintentada
        error.config.baseURL = newURL; // Usar la nueva URL en esta solicitud
        try {
          return await api.request(error.config); // Reintentar con la nueva configuración
        } catch (retryError) {
          console.error(`Error también en el servidor alternativo: ${retryError.message}`);
          throw retryError; // Lanzar el error si ambos servidores fallan
        }
      }
    }
    return Promise.reject(error); // Rechazar otros errores
  }
);

// Función para realizar solicitudes con failover
async function fetchWithFailover(endpoint, options = {}) {
  try {
    const response = await api.request({ url: endpoint, ...options });
    return response.data;
  } catch (error) {
    console.error("Error en fetchWithFailover:", error.message);
    throw error;
  }
}

// Exportar configuración, instancia y funciones
export { api, config, fetchWithFailover };
