import React, { useState, useEffect, useRef } from 'react';
import { Routes, Route, useNavigate, Link, useLocation } from 'react-router-dom';
import logout from '../config/logoutConfig';
import Usuarios from './Usuarios';
import Cotizacion from './Cotizacion';
import Inventario from './Inventario';
import Apu from './Apu';
import Cliente from './Cliente';
import OT from './OT';
import Otros from './Otros';
import CustomAlert from '../components/CustomAlert';
import { config } from '../config/apiConfig';

const API_URL = config.API_URL;
// Iconos blancos usando caracteres unicode
const icons = {
    home: '⌂',
    invoice: '⎙',
    boxes: '☷',
    calculator: '✢',
    users: '⚇',
    clipboard: '⎘',
    userTie: '⚉',
    wrench: '⚒',
    user: '◉',
    sun: '◐',
    moon: '◑',
    signOut: '⏏',
    bell: '🔔'
};

const Dashboard = () => {
    const navigate = useNavigate();
    const role = localStorage.getItem('role') || '';
    const userId = localStorage.getItem('userId');
    const nombreOriginal = localStorage.getItem('nombre') || 'Usuario';
    const nombre = nombreOriginal.charAt(0).toUpperCase() + nombreOriginal.slice(1);
    const [setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') !== 'false');
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [valorUF, setValorUF] = useState(null);
    const [error, setError] = useState(null);
    const today = new Date().toLocaleDateString('es-CL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).split('/').reverse().join('-'); // Formato DD-MM-YYYY
    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'info'
    });

    useEffect(() => {
        // Establecer modo oscuro por defecto si no hay preferencia guardada
        if (!localStorage.getItem('darkMode')) {
            localStorage.setItem('darkMode', 'true');
            setDarkMode(true);
        }
    }, []);

    const handleLogout = async () => {
        try {
            const confirmed = window.confirm('¿Estás seguro de que deseas cerrar sesión?');
            if (confirmed) {
                setLoading(true);
                await logout(navigate);
                localStorage.clear();
                navigate('/');
            }
        } catch (error) {
            console.error('Error al cerrar sesión:', error);
            alert('Hubo un error al cerrar sesión. Por favor intente nuevamente.');
        } finally {
            setLoading(false);
        }
    };

    const toggleDarkMode = () => {
        const newDarkMode = !darkMode;
        setDarkMode(newDarkMode);
        localStorage.setItem('darkMode', newDarkMode);
    };

    const validatePassword = (password) => {
        if (password.length < 6) {
            return 'La contraseña debe tener al menos 6 caracteres';
        }
        if (!/[A-Z]/.test(password)) {
            return 'La contraseña debe contener al menos una mayúscula';
        }
        if (!/[0-9]/.test(password)) {
            return 'La contraseña debe contener al menos un número';
        }
        return '';
    };

    const handleChangePassword = async () => {
        const validationError = validatePassword(newPassword);
        if (validationError) {
            setAlert({
                open: true,
                message: validationError,
                severity: 'error'
            });
            return;
        }

        if (newPassword !== confirmPassword) {
            setAlert({
                open: true,
                message: 'Las contraseñas no coinciden',
                severity: 'error'
            });
            return;
        }

        try {
            const response = await fetch(`${API_URL}/update-password/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    newPassword: newPassword
                })
            });

            if (response.ok) {
                setAlert({
                    open: true,
                    message: 'Contraseña actualizada exitosamente',
                    severity: 'success'
                });
                setShowPasswordModal(false);
                setNewPassword('');
                setConfirmPassword('');
                setPasswordError('');
            } else {
                const data = await response.json();
                setAlert({
                    open: true,
                    message: data.error || 'Error al actualizar la contraseña',
                    severity: 'error'
                });
            }
        } catch (error) {
            console.error('Error:', error);
            setAlert({
                open: true,
                message: 'Error al conectar con el servidor',
                severity: 'error'
            });
        }
    }; 

        useEffect(() => {
            const fetchValorUF = async () => {
                try {
                    
                    const response = await fetch(`https://mindicador.cl/api/uf/${today}`);
                    
                    if (response.ok) {
                        const data = await response.json();
                        if (data && data.serie && data.serie.length > 0) {
                            setValorUF(data.serie[0].valor); // Accede al valor de la UF
                        } else {
                            setError('No se encontraron datos de la UF.');
                        }
                    } else {
                        setError('Error al obtener el valor de la UF.');
                    }
                } catch (error) {
                    setError('Error al conectar con la API.');
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            };

            fetchValorUF();
        }, []);
        

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 flex">
            <nav className={`${darkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'} w-64 h-full fixed p-4 space-y-4 shadow-lg`}>
                <div className="flex items-center justify-between mb-8">
                    <h1 className="text-4xl font-bold">Novatech</h1>
                </div>
                
                <div className="space-y-2">
                    <NavLink to="/dashboard" icon={icons.home} label="Inicio" />
                    <NavLink to="/dashboard/cotizacion" icon={icons.invoice} label="Cotización" />
                    <NavLink to="/dashboard/inventario" icon={icons.boxes} label="Inventario" />
                    <NavLink to="/dashboard/apu" icon={icons.calculator} label="Apu" />
                    <NavLink to="/dashboard/cliente" icon={icons.userTie} label="Cliente" />
                    {role === 'otro' && (
                        <NavLink to="/dashboard/ot" icon={icons.clipboard} label="Orden de Trabajo" />
                    )}
                    {role === 'Administrador' && (
                        <>
                            <NavLink to="/dashboard/users" icon={icons.users} label="Usuarios" />
                            <NavLink to="/dashboard/otros" icon={icons.wrench} label="Otros" />
                        </>
                    )}
                </div>
                {/* Bloque para mostrar el valor de la UF */}
                <div style={{ marginTop: '530px' }} className="p-4 bg-gray-800 text-white rounded-lg shadow-md mb-8">
                    <p className="text-sm text-center">
                        {valorUF ? (
                            <>Valor UF del día {today} es: <span className="font-bold">${valorUF.toLocaleString('es-CL')}</span></>
                        ) : (
                            'Cargando valor UF...'
                        )}
                    </p>
                </div>



                <div className="absolute bottom-8 left-0 w-full px-4">
                    <div className="flex justify-center items-center space-x-4">
                        <UserProfile nombre={nombre} darkMode={darkMode} setShowPasswordModal={setShowPasswordModal} />
                        <DarkModeToggle darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
                        <LogoutButton handleLogout={handleLogout} loading={loading} />
                    </div>
                </div>
            </nav>

            <div className="flex-1 ml-64">
                <main className={`p-8 ${darkMode ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-800'} min-h-[calc(100vh-4rem)]`}>
                    <Routes>
                        <Route path="/" element={<DashboardHome />} />
                        <Route path="cotizacion" element={<Cotizacion />} />
                        <Route path="inventario" element={<Inventario />} />
                        <Route path="apu" element={<Apu />} />
                        <Route path="cliente" element={<Cliente />} />
                        {role === 'otro' && (
                            <>
                            <Route path="ot" element={<OT />} />
                            </>
                        )}
                        {role === 'Administrador' && (
                            <>
                                <Route path="users" element={<Usuarios />} />
                                <Route path="otros" element={<Otros />} />
                            </>
                        )}
                    </Routes>
                </main>
            </div>

            {showPasswordModal && (
                <PasswordModal
                    darkMode={darkMode}
                    newPassword={newPassword}
                    setNewPassword={setNewPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    passwordError={passwordError}
                    setPasswordError={setPasswordError}
                    handleChangePassword={handleChangePassword}
                    setShowPasswordModal={setShowPasswordModal}
                />
            )}

            <CustomAlert
                open={alert.open}
                message={alert.message}
                severity={alert.severity}
                onClose={() => setAlert({...alert, open: false})}
            />
        </div>
    );
};


const NavLink = ({ to, icon, label }) => {
    const location = useLocation();
    const darkMode = localStorage.getItem('darkMode') !== 'false';

    return (
        <Link 
            to={to} 
            className={`w-full text-left p-3 rounded transition-all duration-200 flex items-center
            ${location.pathname === to ? 'bg-blue-600 text-white' : `hover:${darkMode ? 'bg-gray-700' : 'bg-gray-200'}`}`}
        >
            <span className="mr-3">{icon}</span> {label}
        </Link>
    );
};


const UserProfile = ({ nombre, darkMode, setShowPasswordModal }) => (
    <div 
        className={`p-2 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-gray-200'} flex items-center cursor-pointer hover:bg-opacity-80`}
        onClick={() => setShowPasswordModal(true)}
        title="Haz clic para cambiar tu contraseña"
    >
        <span className="mr-2">{icons.user}</span>
        <span className="text-sm">{nombre}</span>
    </div>
);

const DarkModeToggle = ({ darkMode, toggleDarkMode }) => (
    <button 
        onClick={toggleDarkMode}
        className="p-2 rounded-full hover:bg-opacity-20 hover:bg-gray-500"
    >
        <span>{darkMode ? icons.sun : icons.moon}</span>
    </button>
);

const LogoutButton = ({ handleLogout, loading }) => (
    <button
        onClick={handleLogout}
        className="p-2 rounded-full hover:bg-opacity-20 hover:bg-red-500 text-red-500 hover:text-white transition-colors"
        title="Cerrar sesión"
        disabled={loading}
    >
        <span>{icons.signOut}</span>
    </button>
);

const PasswordModal = ({ darkMode, newPassword, setNewPassword, confirmPassword, setConfirmPassword, passwordError, setPasswordError, handleChangePassword, setShowPasswordModal }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-xl w-96`}>
            <h2 className="text-xl font-bold mb-4">Cambiar Contraseña</h2>
            <div className="space-y-4">
                <div>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Nueva contraseña"
                        className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'}`}
                    />
                    <p className="text-xs text-gray-500 mt-1">
                        La contraseña debe tener al menos 6 caracteres, una mayúscula y un número
                    </p>
                </div>
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirmar contraseña"
                    className={`w-full p-2 border rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'}`}
                />
                {passwordError && (
                    <p className="text-red-500 text-sm">{passwordError}</p>
                )}
            </div>
            <div className="flex justify-end space-x-2 mt-6">
                <button
                    onClick={() => {
                        setShowPasswordModal(false);
                        setNewPassword('');
                        setConfirmPassword('');
                        setPasswordError('');
                    }}
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                >
                    Cancelar
                </button>
                <button
                    onClick={handleChangePassword}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Guardar
                </button>
            </div>
            
        </div>
    </div>
);

const DashboardHome = () => {
    const nombreOriginal = localStorage.getItem('nombre') || 'Usuario';
    const nombre = nombreOriginal.charAt(0).toUpperCase() + nombreOriginal.slice(1);
    const role = localStorage.getItem('role') || '';
    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    useEffect(() => {
        const welcomeShown = sessionStorage.getItem('welcomeShown');
        if (!welcomeShown) {
            setAlert({
                open: true,
                message: `¡Bienvenido ${nombre}! Has iniciado sesión como ${role}`,
                severity: 'success'
            });
            sessionStorage.setItem('welcomeShown', 'true');
        }
    }, [nombre, role]);

    return (
        <div className="flex flex-col items-center justify-center h-[60vh]">
            <CustomAlert
                open={alert.open}
                message={alert.message}
                severity={alert.severity}
                onClose={() => setAlert({ ...alert, open: false })}
            />
            <h1 className="text-4xl font-bold mb-4">¡Bienvenido al Sistema de Gestión!</h1>
            <p className="text-xl text-gray-300">Selecciona una opción del menú para comenzar</p>
        </div>
    );
    
};

export default Dashboard;
