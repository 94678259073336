import { saveToken } from "../utils/authToken";
import { api } from "../config/apiConfig"; // Tu instancia de Axios configurada

export async function loginUser(credentials) {
    try {
        const response = await api.post("/login", credentials); // Endpoint de tu API
        const { token } = response.data;

        // Guardar el token con una duración de 24 horas (86400 segundos)
        saveToken(token, 86400);

        return response.data; // Devolver los datos del usuario (opcional)
    } catch (error) {
        console.error("Error al iniciar sesión:", error.message);
        throw error; // Lanzar el error para manejarlo donde se llame a esta función
    }
}
