import React, { useEffect, useState } from 'react';
import CustomAlert from '../components/CustomAlert';
import { api } from '../config/apiConfig';

const ProductoList = () => {
    const [recursos, setRecursos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showCrearInventario, setShowCrearInventario] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [formData, setFormData] = useState(inicializarFormData());
    const [tipoRecursos, setTipoRecursos] = useState([]);
    const [unidadMedida, setUnidadMedida] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const [tipoRecursoSearch, setTipoRecursoSearch] = useState('');
    const [unidadMedidaSearch, setUnidadMedidaSearch] = useState('');
    const [showTipoRecursoDropdown, setShowTipoRecursoDropdown] = useState(false);
    const [showUnidadMedidaDropdown, setShowUnidadMedidaDropdown] = useState(false);
    const userRole = localStorage.getItem('role');
    const [alert, setAlert] = useState(inicializarAlert());
    const darkMode = localStorage.getItem('darkMode') !== 'false';
    const [showImageModal, setShowImageModal] = useState(false);
    const [currentImageUrl, setCurrentImageUrl] = useState('');
    const [currentImageName, setCurrentImageName] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [recursosResponse, tipoRecursosResponse, unidadMedidaResponse] = await Promise.all([
                api.get(`/recurso`),
                api.get(`/tipoRecurso`),
                api.get(`/unidadMedida`)
            ]);

            const recursosConEstado = recursosResponse.data.map(recurso => ({
                ...recurso,
                estado: recurso.estado === null ? 1 : Number(recurso.estado)
            }));

            setRecursos(recursosConEstado);
            setTipoRecursos(tipoRecursosResponse.data);
            setUnidadMedida(unidadMedidaResponse.data);
        } catch (error) {
            setError('¡Hubo un error al obtener los datos!');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (recurso) => {
        if (Number(recurso.estado) === 0) {
            showAlert('No se puede editar recurso inactivo', 'error');
            return;
        }

        setEditingId(recurso.id);
        setFormData({
            nombre: recurso.nombre,
            descripcion: recurso.descripcion,
            cantidad: recurso.cantidad,
            estado: recurso.estado,
            idUnidadMedida: recurso.idUnidadMedida,
            precioPeso: recurso.precioPeso,
            fechaPrecio: recurso.fechaPrecio.split('T')[0],
            idTipoRecurso: recurso.idTipoRecurso,
            urlImagen: recurso.urlImagen
        });
        setTipoRecursoSearch(tipoRecursos.find(tr => tr.id === recurso.idTipoRecurso)?.nombre || '');
        setUnidadMedidaSearch(unidadMedida.find(um => um.id === recurso.idUnidadMedida)?.nombre || '');
        setShowCrearInventario(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: formatearValor(name, value)
        }));
    };


    const handleUnidadMedidaSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!unidadMedidaSearch.trim()) {
                setError('El nombre de la unidad de medida no puede estar vacío');
                return;
            }
            const response = await api.post(`/unidadMedida`, {
                nombre: unidadMedidaSearch,
                descripcion: unidadMedidaSearch
            });
            
            setFormData(prev => ({
                ...prev,
                idUnidadMedida: response.data.id
            }));
            
            fetchData();
            setShowUnidadMedidaDropdown(false);
            
        } catch (error) {
            setError('¡Error al crear la unidad de medida!');
            console.error('Error:', error);
        }
    };

    const handleTipoRecursoSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!tipoRecursoSearch.trim()) {
                setError('El nombre del tipo de recurso no puede estar vacío');
                return;
            }
            const response = await api.post(`/tipoRecurso`, {
                nombre: tipoRecursoSearch,
                descripcion: tipoRecursoSearch
            });
            
            setFormData(prev => ({
                ...prev,
                idTipoRecurso: response.data.id
            }));
            
            fetchData();
            setShowTipoRecursoDropdown(false);
            
        } catch (error) {
            setError('¡Error al crear el tipo de recurso!');
            console.error('Error:', error);
        }
    };

    const handleSearchChange = (e) => {
        const { value } = e.target;
        setSearchTerm(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingId) {
                const currentRecurso = recursos.find(r => r.id === editingId);
                
                await api.put(`/recurso/${editingId}`, {
                    ...formData,
                    estado: currentRecurso.estado,
                    updatedAt: new Date().toISOString()
                });
                showAlert('Producto actualizado exitosamente', 'success');
            } else {
                await api.post(`/recurso`, {
                    ...formData,
                    estado: 1,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString()
                });
                showAlert('Producto creado exitosamente', 'success');
            }
            resetForm();
            const response = await api.get(`/recurso`);
            setRecursos(response.data);
        } catch (error) {
            showAlert(error.response?.data?.message || 'Error al guardar el recurso', 'error');
            console.error('Error:', error);
        }
    };

    const handleToggle = async (id, currentEstado) => {
        const newEstado = currentEstado === 1 ? 0 : 1;
        try {
            await api.put(`/recurso/${id}`, { estado: newEstado });
            setRecursos(prevRecursos => 
                prevRecursos.map(recurso => 
                    recurso.id === id ? { ...recurso, estado: newEstado } : recurso
                )
            );
            showAlert(`Recurso ${newEstado === 1 ? 'habilitado' : 'deshabilitado'} exitosamente`, 'success');
        } catch (error) {
            showAlert('Error al actualizar el estado', 'error');
            console.error('Error actualizando estado:', error);
        }
    };

    const filteredRecursos = recursos.filter(recurso => {
        const searchTermLower = searchTerm.toLowerCase();
        const tipoRecursoNombre = tipoRecursos.find(tr => tr.id === recurso.idTipoRecurso)?.nombre.toLowerCase() || '';
        const unidadMedidaNombre = unidadMedida.find(um => um.id === recurso.idUnidadMedida)?.nombre.toLowerCase() || '';
        
        return (
            recurso.nombre.toLowerCase().includes(searchTermLower) ||
            recurso.precioPeso.toString().includes(searchTermLower) ||
            recurso.cantidad.toString().includes(searchTermLower) ||
            tipoRecursoNombre.includes(searchTermLower) ||
            unidadMedidaNombre.includes(searchTermLower)
        );
    });

    const filteredTipoRecursos = tipoRecursos.filter(tr => 
        tr.nombre.toLowerCase().includes(tipoRecursoSearch.toLowerCase())
    );

    const filteredUnidadMedida = unidadMedida.filter(um =>
        um.nombre.toLowerCase().includes(unidadMedidaSearch.toLowerCase())
    );

    const handleCloseAlert = () => {
        setAlert({ ...alert, open: false });
    };

    const showAlert = (message, severity) => {
        setAlert({
            open: true,
            message,
            severity
        });
    };

    const handleViewImage = (url, name) => {
        setCurrentImageUrl(url);
        setCurrentImageName(name);
        setShowImageModal(true);
    };

    const resetForm = () => {
        setShowCrearInventario(false);
        setEditingId(null);
        setFormData(inicializarFormData());
        setTipoRecursoSearch('');
        setUnidadMedidaSearch('');
    };

    if (loading) {
        return <div className="flex justify-center items-center h-screen text-xl font-semibold">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mr-3"></div>
            Cargando...
        </div>;
    }

    if (error) {
        return <div className="text-red-500 text-center p-4 bg-red-100 rounded-lg m-4">{error}</div>;
    }

    return (
        <div className="p-8 max-w-7xl mx-auto text-black">
            <CustomAlert
                open={alert.open}
                message={alert.message}
                severity={alert.severity}
                onClose={handleCloseAlert}
            />
            <div className="flex justify-between items-center mb-6">
                <h1 className={`text-3xl font-bold ${darkMode ? 'text-white' : 'text-gray-800'}`}>
                    Lista de Productos
                </h1>
                {userRole === 'Administrador' && (
                    <div className="flex gap-4">
                        <button 
                            onClick={() => setShowCrearInventario(true)}
                            className="w-14 h-14 rounded-full bg-green-500 hover:bg-green-600 transition-colors duration-300 text-white flex items-center justify-center shadow-lg hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50"
                        >
                            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
                            </svg>
                        </button>
                    </div>
                )}
            </div>

            <div className="mb-6">
                <div className="relative">
                    <input
                        type="text"
                        name="searchTerm"
                        placeholder="Buscar por nombre, precio, cantidad, tipo de recurso o unidad de medida..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="w-full p-3 pl-10 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-150 ease-in-out"
                    />
                    <svg 
                        className="w-5 h-5 text-gray-400 absolute left-3 top-3.5" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                    >
                        <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth="2" 
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                    </svg>
                </div>
            </div>

            <div className="overflow-x-auto shadow-lg rounded-lg">
                <table className="max-w-full bg-white">
                    <thead className="bg-blue-800 text-white">
                        <tr>
                            <th className="px-4 py-3">Nombre</th>
                            <th className="px-4 py-3">Descripción</th>
                            <th className="px-4 py-3">Cantidad</th>
                            <th className="px-7 py-3">Precio compra</th>
                            <th className="px-4 py-3">Fecha Precio</th>
                            <th className="px-4 py-3">Unidad Medida</th>
                            <th className="px-4 py-3">Tipo Recurso</th>
                            <th className="px-4 py-3">Imagen</th>
                            {userRole === 'Administrador' && (
                                <th className="px-4 py-3">Acciones</th>
                            )}
                        </tr>
                    </thead>
                    <tbody className="text-gray-700">
                        {filteredRecursos.map((recurso) => (
                            <tr key={recurso.id} className="hover:bg-gray-100 transition-colors duration-200">
                                <td className="border-t px-4 py-3">{recurso.nombre}</td>
                                <td className="border-t px-4 py-3">{recurso.descripcion}</td>
                                <td className="border-t px-4 py-3">{recurso.cantidad}</td>
                                <td className="border-t px-4 py-3">$ {recurso.precioPeso.toLocaleString('es-CL')}</td>
                                <td className="border-t px-4 py-3">{new Date(recurso.fechaPrecio).toLocaleDateString()}</td>
                                <td className="border-t px-4 py-3">{unidadMedida.find(um => um.id === recurso.idUnidadMedida)?.nombre}</td>
                                <td className="border-t px-4 py-3">{tipoRecursos.find(tr => tr.id === recurso.idTipoRecurso)?.nombre}</td>
                                <td className="border-t px-4 py-3">
                                    <button onClick={() => handleViewImage(recurso.urlImagen, recurso.nombre)} className="text-blue-500 hover:text-blue-700">
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.477 0 8.268 2.943 9.542 7-1.274 4.057-5.065 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </svg>
                                    </button>
                                </td>
                                {userRole === 'Administrador' && (
                                    <td className="border-t px-4 py-3">
                                        <div className="flex justify-center space-x-2 items-center">
                                            <button 
                                                onClick={() => handleEdit(recurso)}
                                                className={`px-4 py-2 rounded-lg ${Number(recurso.estado) === 1 ? 'bg-cyan-500 hover:bg-cyan-600' : 'bg-gray-400'} text-white transition-colors duration-300`}
                                            >
                                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                                                </svg>
                                            </button>
                                            <label className="relative inline-flex items-center cursor-pointer">
                                                <input 
                                                    type="checkbox" 
                                                    className="sr-only peer"
                                                    checked={Number(recurso.estado) === 1}
                                                    onChange={() => handleToggle(recurso.id, recurso.estado)}
                                                />
                                                <div className={`w-14 h-7 rounded-full transition-colors duration-300 ease-in-out ${Number(recurso.estado) === 1 ? 'bg-green-500' : 'bg-gray-300'}`}>
                                                    <div className={`absolute w-5 h-5 bg-white rounded-full shadow transform transition-transform duration-300 ease-in-out ${Number(recurso.estado) === 1 ? 'translate-x-8' : 'translate-x-1'} top-1`}></div>
                                                </div>
                                            </label>
                                        </div>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {showCrearInventario && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
                    <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-2xl font-bold text-gray-800">
                                {editingId ? 'Editar Producto' : 'Agregar Producto'}
                            </h2>
                            <button 
                                onClick={resetForm}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Nombre</label>
                                <input
                                    type="text"
                                    name="nombre"
                                    value={formData.nombre}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    required
                                    placeholder="Ingrese el nombre del producto"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Descripción</label>
                                <textarea
                                    name="descripcion"
                                    value={formData.descripcion}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 min-h-[100px]"
                                    required
                                    placeholder="Ingrese una descripción del producto"
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-gray-700 font-medium mb-2">Cantidad</label>
                                    <input
                                        type="number"
                                        name="cantidad"
                                        value={formData.cantidad}
                                        onChange={handleInputChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                        required
                                        min="0"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700 font-medium mb-2">Precio Peso</label>
                                    <input
                                        type="number"
                                        name="precioPeso"
                                        value={formData.precioPeso}
                                        onChange={handleInputChange}
                                        className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                        required
                                        min="0"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">Fecha Precio</label>
                                <input
                                    type="date"
                                    name="fechaPrecio"
                                    value={formData.fechaPrecio}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    required
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-gray-700 font-medium mb-2">Unidad de Medida</label>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            value={unidadMedidaSearch}
                                            onChange={(e) => {
                                                setUnidadMedidaSearch(e.target.value);
                                                setShowUnidadMedidaDropdown(true);
                                            }}
                                            onFocus={() => setShowUnidadMedidaDropdown(true)}
                                            placeholder="Buscar o crear unidad de medida..."
                                            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                            required
                                        />
                                        {showUnidadMedidaDropdown && (
                                            <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 max-h-48 overflow-y-auto">
                                                {filteredUnidadMedida.map(um => (
                                                    <div
                                                        key={um.id}
                                                        className="p-2 hover:bg-gray-100 cursor-pointer"
                                                        onClick={() => {
                                                            setFormData(prev => ({ ...prev, idUnidadMedida: um.id }));
                                                            setUnidadMedidaSearch(um.nombre);
                                                            setShowUnidadMedidaDropdown(false);
                                                        }}
                                                    >
                                                        {um.nombre}
                                                    </div>
                                                ))}
                                                {unidadMedidaSearch && !filteredUnidadMedida.length && (
                                                    <div 
                                                        className="p-2 hover:bg-gray-100 cursor-pointer text-blue-500 flex items-center"
                                                        onClick={handleUnidadMedidaSubmit}
                                                    >
                                                        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
                                                        </svg>
                                                        Crear "{unidadMedidaSearch}"
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <label className="block text-gray-700 font-medium mb-2">Tipo de Recurso</label>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            value={tipoRecursoSearch}
                                            onChange={(e) => {
                                                setTipoRecursoSearch(e.target.value);
                                                setShowTipoRecursoDropdown(true);
                                            }}
                                            onFocus={() => setShowTipoRecursoDropdown(true)}
                                            placeholder="Buscar o crear tipo de recurso..."
                                            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                            required
                                        />
                                        {showTipoRecursoDropdown && (
                                            <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 max-h-48 overflow-y-auto">
                                                {filteredTipoRecursos.map(tr => (
                                                    <div
                                                        key={tr.id}
                                                        className="p-2 hover:bg-gray-100 cursor-pointer"
                                                        onClick={() => {
                                                            setFormData(prev => ({ ...prev, idTipoRecurso: tr.id }));
                                                            setTipoRecursoSearch(tr.nombre);
                                                            setShowTipoRecursoDropdown(false);
                                                        }}
                                                    >
                                                        {tr.nombre}
                                                    </div>
                                                ))}
                                                {tipoRecursoSearch && !filteredTipoRecursos.length && (
                                                    <div 
                                                        className="p-2 hover:bg-gray-100 cursor-pointer text-blue-500 flex items-center"
                                                        onClick={handleTipoRecursoSubmit}
                                                    >
                                                        <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
                                                        </svg>
                                                        Crear "{tipoRecursoSearch}"
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium mb-2">URL de la Imagen</label>
                                <input
                                    type="text"
                                    name="urlImagen"
                                    value={formData.urlImagen}
                                    onChange={handleInputChange}
                                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                                    placeholder="Ingrese la URL de la imagen"
                                />
                            </div>

                            {formData.urlImagen ? (
                                <div className="mt-4">
                                    <img src={formData.urlImagen} alt="Imagen del producto" className="max-w-full h-auto" />
                                </div>
                            ) : (
                                <p className="mt-4 text-gray-500">No existe imagen para mostrar</p>
                            )}
                            <div className="flex justify-end space-x-4 mt-6">
                                <button
                                    type="button"
                                    onClick={resetForm}
                                    className="bg-gray-500 hover:bg-gray-600 text-white px-6 py-2 rounded-lg transition-colors duration-300 flex items-center"
                                >
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg transition-colors duration-300 flex items-center"
                                >
                                    <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                    {editingId ? 'Actualizar' : 'Guardar'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {showImageModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
                    <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-md">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-2xl font-bold text-gray-800">{currentImageName}</h2>
                            <button 
                                onClick={() => setShowImageModal(false)}
                                className="text-gray-500 hover:text-gray-700"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                        <img src={currentImageUrl} alt="Imagen del producto" className="max-w-full h-auto" />
                    </div>
                </div>
            )}
        </div>
    );
}

const inicializarFormData = () => ({
    nombre: '',
    descripcion: '',
    cantidad: 0,
    estado: 1,
    idUnidadMedida: '',
    precioPeso: 0,
    fechaPrecio: '',
    idTipoRecurso: '',
    urlImagen: ''
});

const inicializarAlert = () => ({
    open: false,
    message: '',
    severity: 'info'
});

const formatearValor = (name, value) => {
    switch (name) {
        case 'nombre':
        case 'descripcion':
            return value.charAt(0).toUpperCase() + value.slice(1);
        case 'cantidad':
        case 'precioPeso':
            return Number(value);
        default:
            return value;
    }
};

export default ProductoList;
